<template>
  <section class="boardWrap">
    <navi-bar>
      {{$t('front.common.depositApply')}}
    </navi-bar>
    <template v-if="type == 'apply'">
      <div class="tab">
        <a @click="currentTab = 'usdt'" :class="{'on': currentTab == 'usdt'}">USDT (TRC20) 입금신청</a>
        <!--a @click="currentTab = 'ptp'" :class="{'on': currentTab == 'ptp'}">P2P로 구매하기</a-->
      </div>
      <template v-if="currentTab == 'usdt'">
        <div class="moneyread">
          <h3>P2P로 입금신청</h3>
          <ul>
            <li><img src="@/assets_mobile/img/icon_check.svg" /><span>"지갑주소호출" 버튼을 누르면 지갑주소가 호출됩니다. 해당 지갑주소로 원하시는 만큼의 USDT를 전송해주세요</span></li>
            <li v-if="walletAddress" class="url">{{walletAddress}}<a @click="copyToClipboard(walletAddress)"><img src="@/assets/img/icon_copy.svg" /></a></li>
          </ul>
        </div>
        <div class="btnwrap">
          <a class="ok" @click="getWallet">지갑 주소 호출</a>
        </div>
        <ul class="applihave">
          <li><img src="@/assets_mobile/img/icon_moneys.svg" /></li>
          <li>현재 환율</li>
          <li> : <span>{{thousand(usdtToKRW)}}</span></li>
        </ul>
        <div class="btnwrap">
          <a class="ok" @click="goP2P">P2P 거래소 이동</a>
        </div>
      </template>
      <template v-if="currentTab == 'ptp'">
        <div class="moneyread">
          <ul>
            <li><img src="@/assets_mobile/img/icon_check.svg" /><span>"지갑주소호출" 버튼을 누르면 지갑주소가 호출됩니다. 해당 지갑주소로 원하시는 만큼의 USDT를 전송해주세요</span></li>
            <li v-if="walletAddress" class="url">{{walletAddress}}</li>
          </ul>
        </div>
        <div class="btnwrap">
          <a class="ok" @click="getWallet">지갑 주소 호출</a>
        </div>
        <ul class="applihave">
          <li><img src="@/assets_mobile/img/icon_moneys.svg" /></li>
          <li>현재 환율</li>
          <li> : <span>{{thousand(usdtToKRW)}}</span></li>
        </ul>
        <div class="btnwrap">
          <a class="ok" @click="goP2P">P2P 거래소 이동</a>
        </div>
      </template>
      <div class="applylistbtn pb100">
        <a :class="{'on' : type == 'list'}" @click="type = 'list'">{{$t('front.cash.depositCheck')}} ></a>
      </div>
    </template>

    <template v-if="type == 'list'">
      <date-filter-mobile @search="onCashListSearch"
                          @update="onChangeDate"
      />

      <div class="gamebetwrap">
        <template v-if="cashList.length > 0">
          <div class="gamebetlist" v-for="item in cashList" v-bind:key="item.cashIdx">
            <ul class="gamebetdetail textleft">
              <li class="ft13">{{computedCashStatus(item.cashStatus).text}} ({{dateFormat(item.regDate)}})</li>
              <li class="ft15 mt10">{{$t('front.board.processMoney')}}</li>
            </ul>
            <ul class="gamebetdetail textright">
              <li class="mt30 ft15"><span class="rd fb">{{thousand(item.cashAmt)}}</span> {{$t('front.common.money')}}</li>
            </ul>
          </div>
        </template>
        <template v-else>
          <div class="gamebetlist">
            {{$t('front.common.notFoundList')}}
          </div>
        </template>
      </div>
      <pagination-mobile v-if="pageInfo"
                         :pageNum="pageInfo.page"
                         :pageSize="pageInfo.count_per_list"
                         :totalCount="pageInfo.tatal_list_count"
                         @goToPage="onCashListSearchMobile"
      />
      <div class="applylistbtn left mt10 mb100">
        <a :class="{'on' : type == 'apply'}" @click="type = 'apply'"> &lt; {{$t('front.common.depositApply')}}</a>
      </div>
    </template>

  </section>
</template>

<script>

import NaviBar from '@/components/common/mobile/NaviBar'
import PaginationMobile from '@/components/ui/PaginationMobile'
import DateFilterMobile from '@/components/ui/DateFilterMobile'
import Charge from '@/views/member/money/coinCharge'

export default {
  name: 'charge',
  data () {
    return {
      currentTab: 'usdt'
    }
  },
  components: {
    NaviBar,
    PaginationMobile,
    DateFilterMobile
  },
  mixins: [
    Charge
  ]
}
</script>
<style scoped src="@/styles_mobile/betlist.css"></style>
<style scoped src="@/styles_mobile/money.css"></style>
<style scoped src="@/styles_mobile/subcommon.css"></style>
<style scoped src="@/styles_mobile/common.css"></style>

<style scoped>
.tab {display: flex;align-items: center;gap: 10px;margin: 0 25px 15px !important;}
.tab a {width: 150px;height: 30px;text-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);font-size: 12px;
    color: #fff;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.16);
    border-radius: 3px;
    border: 1px solid transparent;
    background-image: linear-gradient(to bottom, #6a6b6f, #404450), linear-gradient(to bottom, #b0772a, #ddb763 16%, #f4d780 25%, #f4d780 46%, #fbf9d8 65%, #f4d780 75%, #b0772a);
    background-origin: border-box;
    background-clip: content-box, border-box;
    display: flex;
    align-items: center;
    justify-content: center;}
.tab a.on {background: #ceb790;}
.url {display: flex;align-items: center;justify-content: center;font-size: 16px;font-weight: bold;word-break: break-all;}
.morebtn {margin-bottom: 10px !important;}
</style>
